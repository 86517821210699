import React, { Component } from "react";
import "./Flash.scss";
import { CSSTransition } from "react-transition-group";

const flashDelay = 3000;

class Flash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.message && !!this.props.message) {
      this.setState({ show: true });
      setTimeout(() => {
        this.setState({ show: false });
      }, flashDelay);
    }
  }

  render() {
    if (!this.props.message) return null;

    return (
      <div className="Flash">
        <CSSTransition
          in={this.state.show}
          timeout={300}
          classNames="transition"
          onExited={this.props.clear}
        >
          <span className="flash-message">{this.props.message}</span>
        </CSSTransition>
      </div>
    );
  }
}

export default Flash;
