import React, { Component } from "react";
import "./Slider.scss";
var externalImages = require.context("./images/external", true);

class Slider extends Component {
  constructor(props) {
    super(props);

    this.style = props.itemStyle || "rect";
    this.size = props.itemSize || "regular";
  }

  renderItems() {
    const items = this.props.items.map(this.renderItem);
    return <ul className="items">{items}</ul>;
  }

  longestItem() {
    let itemLength = item => (item.title + item.details.join(" ")).length;
    return this.props.items.reduce((longest, item) => {
      return itemLength(item) > itemLength(longest) ? item : longest;
    }, this.props.items[0]);
  }

  renderItem = item => {
    const details = item.details.map(detail => {
      return (
        <p key={detail} className="detail">
          {detail}
        </p>
      );
    });

    let itemContents = (
      <div className="item-contents">
        <img
          className={"item-img " + this.style}
          alt={item.title}
          src={externalImages("./" + item.img)}
        />
        <p className="title">{item.title}</p>
        {details}
      </div>
    );

    if (!!item.link) {
      itemContents = (
        <a
          className="item-link"
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {itemContents}
        </a>
      );
    }

    return (
      <li key={item.title} className={"item " + this.size}>
        {itemContents}
      </li>
    );
  };

  renderSpacer() {
    const item = this.longestItem();
    return <ul className="items spacer">{this.renderItem(item)}</ul>;
  }

  render() {
    return (
      <div className="Slider">
        {this.renderItems()}
        {this.renderSpacer()}
      </div>
    );
  }
}

export default Slider;
