import React, { Component } from "react";
import "./ExhibitHighlights.scss";

function Highlight(props) {
  if (!props.value) {
    return null;
  }

  return (
    <div className="highlight">
      <p className="label">{props.label}</p>
      <p className="value">{props.value}</p>
    </div>
  );
}

class ExhibitHighlights extends Component {
  render() {
    let project = this.props.project;
    let classNames = ["ExhibitHighlights"];
    if (!project.hasSections()) classNames.push("page-bottom");

    return (
      <div className={classNames.join(" ")}>
        <div
          className="highlight-background"
          style={{
            backgroundImage: `url(${project.getImgPath()})`
          }}
        />
        <div className={`highlight-contents ${project.getLightMode()}`}>
          <Highlight label="Contents" value={project.contents} />
          <Highlight
            label="Target Demographic"
            value={project.targetDemographic}
          />
          <Highlight label="Est. Budget" value={project.budgetCategory} />
          <Highlight label="Status" value={project.status} />
        </div>
      </div>
    );
  }
}

export default ExhibitHighlights;
