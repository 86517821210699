var covers = require.context("./images/covers", true);
var files = require.context("./files", true);

class ProjectModel {
  constructor(project) {
    for (let [key, value] of Object.entries(project)) {
      this[key] = value;
    }
  }

  hasSections = () => {
    if (!this.unlocked) return false;
    if (!this.securedData) return false;
    const sections = [
      "spotifyPlaylistID",
      "cast",
      "crew",
      "presentation",
      "locations",
      "comparableProductions"
    ];
    for (let i = 0; i < sections.length; i++) {
      if (!!this.securedData[sections[i]]) return true;
    }
    return false;
  };

  getImgPath = () => {
    return covers("./" + this.img);
  };

  getLightMode = () => {
    return this.lightMode || "light";
  };

  getFileLink = file => {
    return files("./" + file);
  };

  getScriptFdxLink = () => {
    if (!this.securedData.scriptFdx) {
      return "";
    }
    return files("./" + this.securedData.scriptFdx);
  };
}

export default ProjectModel;
