import React, { Component } from "react";
import "./ExhibitSections.scss";
import Slider from "./Slider";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as MusicIcon } from "./images/music.svg";
import ReactMarkdown from 'react-markdown'


class SpotifyPlaylist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }

  componentDidMount() {
    this.setState({ show: true });
  }

  render() {
    let playlistID = this.props.playlistID;
    if (!playlistID) {
      return null;
    }

    return (
      <div className="container">
        <CSSTransition in={this.state.show} timeout={3000} classNames="fade-in">
          <div className="spotify-frame">
            <iframe
              src={"https://open.spotify.com/embed/playlist/" + playlistID}
              width="100%"
              height="400"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
              title="Mood Playlist"
            ></iframe>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

function OriginalMusic(props) {
  let music = props.music.map((song, idx) => {
    return (
      <div key={`song-${idx}`} className="original-song">
        <MusicIcon className="music-icon" />
        <div className="details">
          <p className="song-title">{song.title}</p>
          <p className="runtime">{song.runtime}</p>
        </div>
      </div>
    );
  });
  return (
    <div className="container">
      <div className="original-music">{music}</div>
    </div>
  );
}

function Presentation({url}) {
  const src = url + "?start=false&loop=false&delayms=60000"
  return (
    <div className="Presentation">
      <iframe
        title="presentation"
        src={src}
        frameBorder="0"
        width="100%"
        height="100%"
        allowFullScreen={true}
        mozallowfullscreen="true" 
        webkitallowfullscreen="true"
      >
      </iframe>
    </div>
  );
}

function Section(props) {
  if (!props.show) {
    return null;
  }
  return (
    <section className="detail-section">
      <h2 className="section-title">{props.title}</h2>
      {props.children}
    </section>
  );
}

class ExhibitSections extends Component {
  getCrewItems(crew) {
    if (!crew) return [];
    return crew.map(c => {
      return {
        title: c.name,
        details: [c.role],
        img: c.img,
        link: c.link
      };
    });
  }

  getCastItems(cast) {
    if (!cast) return [];
    return cast.map(c => {
      return {
        title: c.actor,
        details: [c.character],
        img: c.img,
        link: c.link
      };
    });
  }

  getLocationItems(locations) {
    if (!locations) return [];
    return locations.map(l => {
      return {
        title: l.description,
        details: [l.example],
        img: l.img
      };
    });
  }

  getProductionItems(productions) {
    if (!productions) return [];
    return productions.map(p => {
      return {
        title: p.title,
        details: [p.year],
        img: p.img,
        link: p.link
      };
    });
  }

  render() {
    const data = this.props.project.securedData;
    if (!data) return null;
    if (!this.props.project.hasSections()) return null;

    return (
      <div className="ExhibitSections">
        <Section title="Summary" show={!!data.summary}>
          <div className="container project-summary">
            <ReactMarkdown>{data.summary}</ReactMarkdown>
          </div>
        </Section>
        <Section title="Presentation" show={!!data.presentation}>
          <Presentation url={data.presentation} />
        </Section>
        <Section title="Original Music" show={!!data.originalMusic}>
          <OriginalMusic music={data.originalMusic} />
        </Section>
        <Section title="Mood Playlist" show={!!data.spotifyPlaylistID}>
          <SpotifyPlaylist playlistID={data.spotifyPlaylistID} />
        </Section>
        <Section title="Crew" show={!!data.crew}>
          <Slider items={this.getCrewItems(data.crew)} itemStyle="circle" />
        </Section>
        <Section title="Sample Cast" show={!!data.cast}>
          <Slider items={this.getCastItems(data.cast)} itemStyle="circle" />
        </Section>
        <Section
          title="Comparable Productions"
          show={!!data.comparableProductions}
        >
          <Slider items={this.getProductionItems(data.comparableProductions)} />
        </Section>
        <Section title="Locations" show={!!data.locations}>
          <Slider
            items={this.getLocationItems(data.locations)}
            itemSize="large"
          />
        </Section>
      </div>
    );
  }
}

export default ExhibitSections;
