import React, { Component } from "react";
import "./AuthPanel.scss";
import Auth from "./Auth";
import { ReactComponent as LockIcon } from "./images/lock.svg";

const authDelay = 400;

class AuthPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      password: ""
    };
  }

  resetState() {
    this.setState({ error: null, loading: false, password: "" });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.projectID !== this.props.projectID) {
      this.resetState();
    }
    if (!prevState.loading && this.state.loading && !!this.state.password) {
      setTimeout(() => {
        Auth.authenticate(this.state.password, this.handleAuthResult);
      }, authDelay);
    }
  }

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.setState({ loading: true });
    }
  };

  handleAuthResult = result => {
    if (!!result.error) {
      this.setState({ loading: false, error: result.error });
      return;
    }
    if (!result.securedData[this.props.projectID]) {
      this.setState({ loading: false, error: "project access denied" });
      return;
    }
    const authToken = this.state.password; // save password before resetting
    this.resetState();
    Auth.saveToken(authToken);
    this.props.accessGranted();
  };

  handleChange = e => {
    if (!!this.state.error) this.setState({ error: null });
    this.setState({ password: e.target.value });
  };

  render() {
    let classNames = ["AuthPanel"];
    if (!!this.props.lightMode) classNames.push(this.props.lightMode);
    return (
      <div className={classNames.join(" ")}>
        <LockIcon className="lock-icon" />
        <h2 className="auth-header">Access Restricted</h2>
        <p className="auth-detail">
          Password required to access additional information about this project.
        </p>
        <input
          className="auth-password"
          type="password"
          name="password"
          placeholder="password"
          onKeyDown={this.handleKeyDown}
          value={this.state.password}
          onChange={this.handleChange}
        />
        {this.state.loading ? (
          <p className="auth-loading">Loading...</p>
        ) : (
          <p className="auth-error">{this.state.error}</p>
        )}
      </div>
    );
  }
}

export default AuthPanel;
