import React, { Component } from "react";
import Gallery from "./Gallery";
import ExhibitGallery from "./ExhibitGallery";
import TeamMember from "./TeamMember";
import "./Home.scss";
import { CSSTransition } from "react-transition-group";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";
import data from "./data/data.json";
import ashlyAboutMeImg from "./images/ashly-about-me-2.jpg";
import { ReactComponent as InstagramLogo } from "./images/instagram-glyph-black.svg";

class Home extends Component {
  constructor(props) {
    super(props);

    const projectID = props.router.searchParams.get("p");

    this.state = {
      showProject: !!projectID,
      projectID: projectID || "",
      loaded: false,
    };
  }

  componentDidMount() {
    this.setState({ loaded: true });

    if (!!this.state.projectID) {
      ReactGA.send({
        hitType: "pageview",
        page: "/projects/" + this.state.projectID,
        title: this.state.projectID,
      });
    } else {
      ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Home",
      });
    }
  }

  closeProject = () => {
    this.props.router.setSearchParams({});
    this.setState({ showProject: false });
  };

  openProject = (projectID) => {
    ReactGA.send({
      hitType: "pageview",
      page: "/projects/" + this.state.projectID,
      title: this.state.projectID,
    });
    this.props.router.setSearchParams({ p: projectID });
    this.setState({
      showProject: true,
      projectID: projectID,
    });
  };

  renderTeamMembers = () => {
    let teamMembers = data.team.map((t, idx) => (
      <TeamMember key={`team-${idx}`} teamMember={t} />
    ));
    return <div className="team-members">{teamMembers}</div>;
  };

  render() {
    return (
      <div>
        <Helmet bodyAttributes={{ style: "background-color: #1a1714" }} />
        {this.state.showProject && (
          <ExhibitGallery
            projectID={this.state.projectID}
            openProject={this.openProject}
            close={this.closeProject}
          />
        )}
        <div className="Home">
          <CSSTransition
            in={this.state.loaded}
            timeout={3000}
            classNames="header"
          >
            <header className="header">
              <div className="headings">
                <h1>
                  <span className="bg-gradient">Ashly Edwards Huntington</span>
                </h1>
                <div className="separator" />
                <h2>Writer, Storyteller, and Creative Visionary.</h2>
              </div>
              <div className="hero-container">
                <div className="hero" />
              </div>
            </header>
          </CSSTransition>
          <main>
            <section className="purpose">
              <h1>Purpose.</h1>
              <p>
                Writers increase the focal length of the lens through which we
                view life. They provide us with new perspectives and experiences
                we might otherwise avoid and role models we’ve been unknowingly
                waiting for. Writers are the silent, behind-the-scenes teachers
                that softly prod us to think differently about the world, beyond
                what <em>is</em> and instead to what <em>could be</em>. It is
                there, outside the comforts of our own homes, that stories whet
                our curiosity, inspire empathy, and teach us. Whether a writer
                makes us laugh, cry, think, question, fear, or dream—they, above
                all else, have the responsibility to nurture our ability to
                connect.
              </p>
              <p>
                I believe that connection is the core of a better and brighter
                future for all.
              </p>
            </section>
            <section className="quote">
              <p>
                <span className="open-quote">“</span>
                <span className="quote-text">
                  Imagination will often carry us to worlds that never were. But
                  without it we go nowhere
                </span>
                <span className="author">Carl Sagan</span>
              </p>
            </section>
            <section>
              <h1>Select Projects.</h1>
              <Gallery openProject={this.openProject} />
            </section>
            <section className="purpose">
              <h1>About the Author.</h1>
              <img
                className="side-photo"
                src={ashlyAboutMeImg}
                alt="Ashly Huntington"
              />
              <p>
                Ashly Huntington is a storyteller driven by an insatiable
                curiosity about the unknown and a passion for bridging diverse
                fields such as microbiology, philosophy, neuroscience, frontier
                exploration, and engineering. Her work spans multiple
                genres—including comedy, drama, adventure, live-action, and
                animation—each infused with a commitment to authenticity and a
                deep exploration of the human condition. Whether through dark,
                introspective narratives or uplifting moments, her stories aim
                to probe the depths of the mind and soul, continuously pushing
                the boundaries of conventional storytelling.
              </p>
              <p>
                Ashly believes it is essential to expand narrative possibilities 
                with new storytelling and production technologies without diminishing 
                the intrinsic value of human experience. Her goal is
                to craft stories that not only entertain but also connect on a
                deeper level, fostering empathy and offering fresh perspectives
                on life's complexities.
              </p>
              <p>
                Beyond writing, Ashly is a perpetual student, deeply committed
                to academic rigor, especially in scientific fields. This
                dedication to learning fuels her creative and strategic
                pursuits, including playing classical piano, composing music,
                and engaging in strategy games like Civilization. Her
                exploration extends outdoors through hiking and archery, and is
                captured in{" "}
                <Link href="https://punchmagazine.com/perfect-shot-mist/">
                  her photography
                </Link>
                .
              </p>
            </section>
            <section className="purpose">
              <h1>Inspirations.</h1>
              <p>
                Ashly’s creative vision is deeply influenced by a variety of
                books and films that challenge the intellect and stir the
                emotions. Among her favorite reads are Viktor Frankl's{" "}
                <Link href="https://en.wikipedia.org/wiki/Man's_Search_for_Meaning">
                  Man's Search for Meaning
                </Link>
                , a profound exploration of resilience and the human spirit;
                Orson Scott Card's{" "}
                <Link href="https://en.wikipedia.org/wiki/Ender%27s_Game">
                  Ender's Game
                </Link>
                , which captures the complexities of leadership and morality;
                Allie Brosh's{" "}
                <Link href="https://en.wikipedia.org/wiki/Hyperbole_and_a_Half">
                  Hyperbole and a Half
                </Link>
                , which blends humor with raw emotional truth; Patrick
                Rothfuss's{" "}
                <Link href="https://en.wikipedia.org/wiki/The_Name_of_the_Wind">
                  The Name of the Wind
                </Link>
                , a masterful tale of adventure and mystery; and Charlie
                Mackesy's{" "}
                <Link href="https://en.wikipedia.org/wiki/The_Boy,_the_Mole,_the_Fox_and_the_Horse">
                  The Boy, the Mole, the Fox and the Horse
                </Link>
                , a beautiful meditation on friendship and kindness.
              </p>
              <p>
                On the screen, she draws inspiration from a range of films: from
                the epic scope and complex narratives of{" "}
                <Link href="https://en.wikipedia.org/wiki/Dune:_Part_Two">
                  Dune: Part Two (2024)
                </Link>{" "}
                and the visionary reimagining in{" "}
                <Link href="https://en.wikipedia.org/wiki/Star_Trek_(2009_film)">
                  Star Trek (2009)
                </Link>
                , to the deep character studies in Christopher Nolan's{" "}
                <Link href="https://en.wikipedia.org/wiki/Batman_Begins">
                  Batman Begins
                </Link>{" "}
                and{" "}
                <Link href="https://en.wikipedia.org/wiki/The_Dark_Knight">
                  The Dark Knight
                </Link>
                .{" "}
                <Link href="https://en.wikipedia.org/wiki/There_Will_Be_Blood">
                  There Will Be Blood
                </Link>{" "}
                examines the darker aspects of ambition and human nature, while{" "}
                <Link href="https://en.wikipedia.org/wiki/How_to_Train_Your_Dragon">
                  How to Train Your Dragon
                </Link>{" "}
                celebrates the bonds between creatures and the power of
                understanding.
              </p>
              <p>
                These works resonate with Ashly’s creative ethos and continually
                inspire her own storytelling across various genres.
              </p>
            </section>
            <section className="contact">
              <h1>Team.</h1>
              {this.renderTeamMembers()}
            </section>
          </main>
          <footer>
            <p className="social">
              <a
                href="https://www.instagram.com/aehgallery/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramLogo className="icon" />
              </a>
            </p>
            <p>
              <span>&mdash;&mdash;</span>
              <span>
                Website &amp; Graphics by
                <br />
                Ashly Huntington &amp;{" "}
                <a
                  href="http://ryanatallah.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ryan Atallah
                </a>
              </span>{" "}
              <span>&mdash;&mdash;</span>
            </p>
          </footer>
        </div>
      </div>
    );
  }
}

function Link({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

export const withSearchParams = (Component) => {
  function ComponentWithRouterProp(props) {
    let [searchParams, setSearchParams] = useSearchParams();
    return <Component {...props} router={{ searchParams, setSearchParams }} />;
  }
  return ComponentWithRouterProp;
};

export default withSearchParams(Home);
