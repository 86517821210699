import React, { Component } from "react";
import Exhibit from "./Exhibit";
import ExhibitGalleryNav from "./ExhibitGalleryNav";
import "./ExhibitGallery.scss";
import ProjectData from "./ProjectData";
import { CSSTransition } from "react-transition-group";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

class ExhibitGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      projects: ProjectData.getProjects(),
      scrollHeight: 0,
      flashMessage: "",
    };

    this.targetRef = React.createRef();
    this.targetElement = null;
  }

  componentDidMount() {
    this.setState({ show: true });
    this.targetElement = this.targetRef.current;
    disableBodyScroll(this.targetElement);
  }

  componentWillUnmount() {
    enableBodyScroll(this.targetElement);
  }

  getProjectIdx = () => {
    return ProjectData.getProjectIdxByID(this.props.projectID);
  };

  getCurrProject() {
    return this.state.projects[this.getProjectIdx()];
  }

  close = () => {
    this.setState({ show: false });
  };

  handleKeyDown(e) {
    console.log(e);
  }

  updateProjects = () => {
    this.setState({ projects: ProjectData.getProjects() });
  };

  render() {
    return (
      <div className="ExhibitGallery">
        <CSSTransition
          in={this.state.show}
          timeout={300}
          classNames="transition"
          onExited={this.props.close}
        >
          <div ref={this.targetRef} className="scroll-container">
            <ExhibitGalleryNav
              projectIdx={this.getProjectIdx()}
              projects={this.state.projects}
              close={this.close}
              openProject={this.props.openProject}
            />
            <div className="exhibit-content">
              <Exhibit
                project={this.getCurrProject()}
                updateProjects={this.updateProjects}
              />
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default ExhibitGallery;
