import React, { Component } from "react";
import "./SongEnd.scss";

class SongEnd extends Component {
  constructor(props) {
    super(props);
    this.endRef = React.createRef();
  }

  componentDidMount() {
    this.props.registerPosition(this.endRef.current.offsetTop);
  }

  render() {
    return (
      <p className="SongEnd" ref={this.endRef}>
        <span className="line"></span>
        <span className="label">Music End</span>
        <span className="line"></span>
      </p>
    );
  }
}

export default SongEnd;
