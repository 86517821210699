import data from "./data/data.json";
import ReactGA from "react-ga4";

var CryptoJS = require("crypto-js");

const passwordKeyLength = 4;

let Auth = {
  getToken: () => {
    return localStorage.getItem("authToken");
  },

  saveToken: (token) => {
    localStorage.setItem("authToken", token);
  },

  clearToken: () => {
    localStorage.removeItem("authToken");
  },

  authenticate: (password, callback) => {
    const invalidPasswordResponse = { error: "invalid password" };
    if (password.length < passwordKeyLength + 1) {
      callback(invalidPasswordResponse);
      return;
    }
    const key = password.substring(0, passwordKeyLength);
    const secret = password.substring(passwordKeyLength, password.length);
    const encryptedData = data.encryptedData[key];
    if (!encryptedData) {
      callback(invalidPasswordResponse);
      return;
    }
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secret);
      console.log("auth'd");

      const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      ReactGA.set({ user_id: userData.userID });

      callback({ securedData: userData.data });
    } catch (err) {
      console.error("decryption failure:", err);
      callback(invalidPasswordResponse);
      return;
    }
  },
};

export default Auth;
