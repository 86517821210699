import React, { Component } from "react";
import "./SongPlayer.scss";
var audio = require.context("./audio", true);

const round = num => Math.round((num + Number.EPSILON) * 100) / 100;

class SongPlayer extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = {
      position: "sticky",
      offsetTop: 0
    };
    this.initialOffset = 0; // to be set
  }

  componentDidUpdate(prevProps) {
    if (prevProps.audioLock && !this.props.audioLock) {
      this.fadeOut();
    }
  }

  componentDidMount = () => {
    this.initialOffset = this.audioRef.current.offsetTop;
    window.addEventListener("scroll", this.handleScroll);
    this.audioRef.current.onplay = event => {
      this.props.onPlay();
    };
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  fadeOut = () => {
    if (this.audioRef.current.paused) return;
    const interval = 0.1;
    var timePassed = 0;
    var fadeAudio = setInterval(() => {
      if (round(timePassed) >= 1) {
        this.audioRef.current.pause();
        this.audioRef.current.volume = 1;
        clearInterval(fadeAudio);
      } else {
        let vol = (1 + Math.cos(timePassed * Math.PI)) / 2;
        this.audioRef.current.volume = vol;
        timePassed += interval;
      }
    }, interval * 1000);
  };

  handleScroll = event => {
    if (!this.props.endPosition) return;
    let bottom = window.scrollY + this.audioRef.current.offsetHeight;
    if (this.state.position === "sticky" && bottom > this.props.endPosition) {
      this.setState({
        position: "relative",
        offsetTop: this.audioRef.current.offsetTop - this.initialOffset
      });
      this.fadeOut();
    }
    if (
      this.state.position === "relative" &&
      bottom <= this.props.endPosition
    ) {
      this.setState({ position: "sticky", offsetTop: 0 });
    }
  };

  render() {
    if (!this.props.name) {
      throw new Error("can't render SongPlayer without name");
    }

    const fileName = this.props.name + ".mp3";
    return (
      <audio
        className="SongPlayer"
        style={{ position: this.state.position, top: this.state.offsetTop }}
        controls
        loop
        preload="metadata"
        ref={this.audioRef}
      >
        <source src={audio("./" + fileName)} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    );
  }
}

export default SongPlayer;
