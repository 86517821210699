import React, { Component } from "react";
import "./ExhibitGalleryNav.scss";

class ExhibitGalleryNav extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    // necessary for keyboard input to work
    this.ref.current.addEventListener("keydown", this.handleKeyDown.bind(this));
    this.ref.current.focus();
  }

  stateIndicators = () => {
    let indicators = [];
    let prevCategory = this.props.projects[0].category;
    for (let i = 0; i < this.props.projects.length; i++) {
      let classes = ["indicator"];
      let project = this.props.projects[i];
      if (i === this.props.projectIdx) classes.push("active");
      if (project.category !== prevCategory) classes.push("category-start");
      indicators.push(
        <span
          key={i}
          className={classes.join(" ")}
          onClick={() => {
            this.props.openProject(this.props.projects[i].id);
          }}
        >
          •
        </span>
      );
      prevCategory = project.category;
    }
    return <div className="state-indicators">{indicators}</div>;
  };

  nextProject = () => {
    var newProjectIdx = this.props.projectIdx + 1;
    if (this.props.projectIdx === this.props.projects.length - 1) {
      newProjectIdx = 0;
    }
    this.props.openProject(this.props.projects[newProjectIdx].id);
  };

  prevProject = () => {
    var newProjectIdx = this.props.projectIdx - 1;
    if (this.props.projectIdx === 0) {
      newProjectIdx = this.props.projects.length - 1;
    }
    this.props.openProject(this.props.projects[newProjectIdx].id);
  };

  handleKeyDown(e) {
    switch (e.keyCode) {
      case 37: // left
        this.prevProject();
        return;
      case 39: // right
        this.nextProject();
        return;
      case 27: // esc
        this.props.close();
        return;
      default:
        return;
    }
  }

  render() {
    return (
      <div className="ExhibitGalleryNav" tabIndex="0" ref={this.ref}>
        <nav className="nav-container project-nav">
          <div className="nav-button prev" onClick={this.prevProject}></div>
          {this.stateIndicators()}
          <div className="nav-button next" onClick={this.nextProject}></div>
        </nav>
        <div className="nav-container close-nav">
          <div className="nav-button close" onClick={this.props.close}></div>
        </div>
      </div>
    );
  }
}

export default ExhibitGalleryNav;
