import data from "./data/data.json";
import Auth from "./Auth";
import ProjectModel from "./ProjectModel";

class ProjectData {
  static getProjectIdxByID(id) {
    let projectIdx = data.projects.findIndex((p) => p.id === id);
    if (projectIdx === -1) {
      return null;
    }
    return projectIdx;
  }

  static getProjects() {
    let projects = data.projects;
    let authToken = Auth.getToken();
    if (!!authToken) {
      Auth.authenticate(authToken, (result) => {
        if (!!result.error) {
          alert(result.error);
          return [];
        }
        Object.entries(result.securedData).forEach((entry) => {
          const projectID = entry[0];
          const securedProjectData = entry[1];
          projects = projects.map((proj) =>
            proj.id === projectID
              ? Object.assign(proj, {
                  unlocked: true,
                  securedData: securedProjectData,
                })
              : proj
          );
        });
      });
    }

    return projects.map((p) => new ProjectModel(p));
  }

  static getProject(id) {
    return this.getProjects().find((p) => p.id === id);
  }
}

export default ProjectData;
