import React, { Component } from "react";
import convert from "xml-js";
import "./Script.scss";
import SongPlayer from "./SongPlayer";
import SongEnd from "./SongEnd";
import ProjectData from "./ProjectData";
// import { useParams } from "react-router-dom";

class Script extends Component {
  constructor(props) {
    super(props);

    // let { projectID } = useParams();

    this.state = {
      loading: true,
      project: ProjectData.getProject("just-super"),
      scriptJSON: null,
      songEndPositions: {}
    };

    fetch(this.state.project.getScriptFdxLink())
      .then(resp => resp.text())
      .then(xmlStr => {
        let json = convert.xml2js(xmlStr, {
          compact: true,
          spaces: 4,
          alwaysArray: ["Text", "ScriptNote"]
        });
        this.setState({ scriptJSON: json, loading: false });
      })
      .catch(() => alert("oh no!"));
  }

  onPlay = songID => {
    return () => {
      this.setState({ currentlyPlayingSong: songID });
    };
  };

  initializeSongRegistry = () => {
    this.songRegistry = {};
  };

  registerSong = songName => {
    let num = 1;
    if (songName in this.songRegistry) {
      num = this.songRegistry[songName] + 1;
    }
    this.songRegistry[songName] = num;
    return this.newSongID(songName, num);
  };

  newSongID = (songName, num) => {
    return songName + ":" + num;
  };

  getRegisteredSongID = songName => {
    let songNum = this.songRegistry[songName];
    if (!songNum) return null;
    return this.newSongID(songName, songNum);
  };

  registerSongEndPosition = songID => {
    return position => {
      this.setState(prevState => {
        let positions = Object.assign({}, prevState.songEndPositions);
        positions[songID] = position;
        return { songEndPositions: positions };
      });
    };
  };

  renderScriptContents = () => {
    this.initializeSongRegistry();

    const validateParagraphType = pType => {
      let className = pType.replace(/\s+/g, "-").toLowerCase();
      const valid = [
        "action",
        "character",
        "dialogue",
        "scene-heading",
        "parenthetical",
        "general",
        "transition"
      ];
      if (!valid.includes(className)) {
        throw new Error("invalid paragraph className: " + className);
      }
      return className;
    };

    const validateTextStyle = textStyle => {
      let classNames = textStyle.toLowerCase().split("+");
      const valid = ["italic", ""];
      if (!valid.some(val => classNames.indexOf(val) === -1)) {
        throw new Error("invalid textStyle: " + textStyle);
      }
      return classNames.join(" ");
    };

    const detectSongCue = p => {
      return detectMusicMark(p, "Music Cue");
    };

    const detectSongEnd = p => {
      return detectMusicMark(p, "Music End");
    };

    const detectMusicMark = (p, type) => {
      let notes = p["ScriptNote"];
      if (!!notes) {
        let note = notes.find(sn => sn["_attributes"]["Type"] === type);
        if (!!note) return note["_attributes"]["Name"];
      }
      return null;
    };

    const renderParagraph = (p, i) => {
      let pClass = validateParagraphType(p["_attributes"]["Type"]);
      return (
        <p className={pClass} key={i}>
          {p["Text"].map((s, j) => {
            let sClass = "";
            if ("_attributes" in s) {
              sClass = validateTextStyle(s["_attributes"]["Style"]);
            }
            return (
              <span key={j} className={sClass}>
                {s["_text"]}
              </span>
            );
          })}
        </p>
      );
    };

    const renderSongPlayer = cuedSong => {
      let songID = this.registerSong(cuedSong);
      return (
        <SongPlayer
          key={songID + "-start"}
          name={cuedSong}
          onPlay={this.onPlay(songID)}
          audioLock={this.state.currentlyPlayingSong === songID}
          endPosition={this.state.songEndPositions[songID]}
        />
      );
    };

    const renderSongEnd = (endedSong, i) => {
      let songID = this.getRegisteredSongID(endedSong);
      if (!songID) throw new Error("song hasn't started: " + endedSong);
      return (
        <SongEnd
          key={"song-end-" + i}
          registerPosition={this.registerSongEndPosition(songID)}
        />
      );
    };

    const renderParagraphs = pgs => {
      let paragraphs = [];
      pgs.forEach((p, i) => {
        let cuedSong = detectSongCue(p);
        if (!!cuedSong) {
          paragraphs.push(renderSongPlayer(cuedSong));
        }

        paragraphs.push(renderParagraph(p, i));

        let endedSong = detectSongEnd(p);
        if (!!endedSong) {
          paragraphs.push(renderSongEnd(endedSong));
        }
      });
      return paragraphs;
    };

    let pgs = this.state.scriptJSON["FinalDraft"]["Content"]["Paragraph"];
    return <div className="script-contents">{renderParagraphs(pgs)}</div>;
  };

  render() {
    return (
      <div className="Script">
        <div className="script-header">
          <div className="button">
            <span className="back-button">&#x3008; </span>
            {this.state.project.title}
          </div>
        </div>

        <div className="script">
          {this.state.loading && (
            <div className="loading-indicator">Loading...</div>
          )}
          {!this.state.loading && this.renderScriptContents()}
        </div>
      </div>
    );
  }
}

export default Script;
