import React, { Component } from "react";
var ratings = require.context("./images/ratings", true);

class Rating extends Component {
  getImgPath = () => {
    var fileName = "rating-";
    switch (this.props.rating) {
      case "G":
        fileName += "g";
        break;
      case "PG":
        fileName += "pg";
        break;
      case "PG-13":
        fileName += "pg-13";
        break;
      case "R":
        fileName += "r";
        break;
      default:
        fileName += "not-rated";
    }
    return ratings("./" + fileName + ".png");
  };

  render() {
    return (
      <img className="Rating" alt={this.props.rating} src={this.getImgPath()} />
    );
  }
}

export default Rating;
