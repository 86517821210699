import React, { Component } from "react";
import "./Gallery.scss";
import data from "./data/data.json";
import VanillaTilt from "vanilla-tilt";

var images = require.context("./images/projects", true);

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currCategoryIdx: 0,
      displayModal: false,
    };
  }

  selectCategory(idx) {
    return (e) => {
      this.setState({
        currCategoryIdx: idx,
      });
    };
  }

  renderTabs() {
    const tabs = data.categories.map((category, idx) => (
      <li
        className={this.state.currCategoryIdx === idx ? "selected" : ""}
        key={category.name}
        onClick={this.selectCategory(idx)}
      >
        {category.name}
      </li>
    ));
    return <ul className="tabs">{tabs}</ul>;
  }

  renderCurrentTab() {
    const categoryID = data.categories[this.state.currCategoryIdx].id;
    const projects = data.projects
      .filter((p) => p.category === categoryID)
      .map(this.renderProject);
    return <ul className="projects">{projects}</ul>;
  }

  renderProject = (project) => {
    return (
      <Project
        key={project.id}
        onClick={() => {
          this.props.openProject(project.id);
        }}
        project={project}
      />
    );
  };

  renderSpacer() {
    const project = data.projects[0];
    return <ul className="projects spacer">{this.renderProject(project)}</ul>;
  }

  render() {
    return (
      <div className="Gallery">
        {this.renderTabs()}
        {this.renderCurrentTab()}
        {this.renderSpacer()}
      </div>
    );
  }
}

function Project(props) {
  const { project, onClick } = props;
  const tiltRef = React.useRef(null);

  React.useEffect(() => {
    const tiltNode = tiltRef.current;
    VanillaTilt.init(tiltNode, {
      max: 15,
      speed: 300,
      glare: true,
      "max-glare": 0.3,
      scale: 1.05,
      perspective: 1000,
    });

    // Clean up function to destroy instance on unmount
    return () => {
      if (tiltNode) tiltNode.vanillaTilt.destroy();
    };
  }, []);

  return (
    <li onClick={onClick}>
      <div
        ref={tiltRef}
        className="project-img"
        style={{ backgroundImage: `url(${images("./" + project.img)})` }}
      ></div>
      <p className="title">{project.title}</p>
      <p className="detail">{project.genre}</p>
      <p className="detail">{project.format}</p>
    </li>
  );
}

export default Gallery;
