import React, { Component } from "react";
import "./Exhibit.scss";
import Flash from "./Flash";
import Rating from "./Rating";
import ExhibitSections from "./ExhibitSections";
import ExhibitHighlights from "./ExhibitHighlights";
import AuthPanel from "./AuthPanel";
import { Link } from "react-router-dom";
import { ReactComponent as ScriptIcon } from "./images/script.svg";
import { ReactComponent as SummaryIcon } from "./images/summary.svg";

class Exhibit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flashMessage: "",
    };
  }

  clearFlash = () => {
    this.setState({ flashMessage: "" });
  };

  accessGranted = () => {
    this.setState({ flashMessage: "Access Granted" });
    this.props.updateProjects();
  };

  renderFileIcon = (icon) => {
    switch (icon) {
      case "script":
        return <ScriptIcon className="file-icon script" />;
      case "summary":
        return <SummaryIcon className="file-icon summary" />;
      default:
        throw new Error("unrecognized file icon: " + icon);
    }
  };

  render() {
    let classNames = ["Exhibit"];
    let project = this.props.project;
    if (!project.unlocked) classNames.push("locked");
    classNames.push(project.getLightMode());

    return (
      <div className={classNames.join(" ")}>
        <Flash message={this.state.flashMessage} clear={this.clearFlash} />
        <div
          className="exhibit-header"
          style={{
            backgroundImage: `url(${project.getImgPath()})`,
          }}
        >
          <div className="top-info">
            <h1 className="project-title">{project.title}</h1>
            <p className="format">{project.format}</p>
          </div>
          <div className="bottom-info">
            <p className="genre">
              <Rating rating={project.rating} /> {project.genre}
            </p>
            {project.unlocked && !!project.securedData.synopsis && (
              <p className="synopsis">{project.securedData.synopsis}</p>
            )}
            {project.unlocked && (
              <div className="project-buttons">
                {project.securedData.files &&
                  project.securedData.files.map((f, idx) => (
                    <a
                      key={`file-${idx}`}
                      href={project.getFileLink(f.file)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="project-button"
                    >
                      {this.renderFileIcon(f.icon)}
                      <div className="project-button-label">{f.name}</div>
                    </a>
                  ))}
                {project.securedData.scriptFdx && false && (
                  <Link to="/script">
                    <div className="project-button">
                      <ScriptIcon className="project-button-icon" />
                      <div className="project-button-label">Script</div>
                    </div>
                  </Link>
                )}
              </div>
            )}
          </div>
          {project.unlocked || (
            <AuthPanel
              lightMode={project.getLightMode()}
              projectID={project.id}
              accessGranted={this.accessGranted}
            />
          )}
        </div>
        <ExhibitHighlights project={project} />
        <ExhibitSections project={project} />
      </div>
    );
  }
}

export default Exhibit;
