import React, { Component } from "react";
import "./TeamMember.scss";

let teamPhotos = require.context("./images/team", true);
let firmLogos = require.context("./images/firms", true);

class TeamMember extends Component {
  getTeamPhotoPath = img => {
    return teamPhotos("./" + img);
  };

  getFirmLogoPath = img => {
    return firmLogos("./" + img);
  };

  renderAddress = () => {
    return this.props.teamMember.address.map((a, idx) => (
      <span key={`add-${idx}`} className="address-line">{a}</span>
    ));
  };

  render() {
    const tm = this.props.teamMember;
    return (
      <a
        href={tm.link}
        target="_blank"
        rel="noopener noreferrer"
        className="TeamMember"
      >
        <img
          className="member-photo"
          alt={tm.name}
          src={this.getTeamPhotoPath(tm.photo)}
        />
        <div className="member-details">
          <div className="member-name">
            {tm.name}
          </div>
          <div className="member-detail member-title">{tm.title}</div>
          <div className="member-detail member-email">
            {tm.email}
          </div>
          <div className="member-detail member-phone">{tm.phone}</div>
          <img
            className="member-firm"
            alt={tm.firm}
            src={this.getFirmLogoPath(tm.firmLogo)}
          />
          <div className="member-detail member-address">
            {this.renderAddress()}
          </div>
        </div>
      </a>
    );
  }
}

export default TeamMember;
